import { onDestroy } from "svelte"
import { SelectedDeviceStore } from "../../../../../../stores/SelectedDeviceStore"
import { DeviceGroup, DeviceRPi, type Device } from "luxedo-data"
import { Controller } from "svelte-comps/stores"
import { getCurrentUser } from "../../../../../../stores/UserStore"
import AdvancedActionsRollbackReport from "./AdvancedActions_RollbackReport.svelte"

export type ProjectorActionType = "HDMI_FIX" | "REBOOT" | "STOP_PLAYBACK" | "FW_ROLLBACK"

class ProjActionsCntl extends Controller<Array<ProjectorActionType>> {
	declare device: Device

	constructor() {
		super([])

		SelectedDeviceStore.subscribe(this.onDeviceSelect)
	}

	eidosListener: string
	/**
	 * Called when the selected device changes
	 * @param device the device being selected
	 */
	onDeviceSelect = (device: Device) => {
		if (this.eidosListener) {
			this.device?.removeUpdateListener(this.eidosListener)
			this.eidosListener = undefined
		}

		this.device = device
		if (!device || !device.isOnline) return this.reset()

		this.eidosListener = device.addUpdateListener(this.onDeviceUpdate)
		this.onDeviceUpdate(device)
	}

	/**
	 * Called when the selected device has an eidos update or the selected device changes (see onDeviceSelect).
	 * This parses the device and device state to determine the available actions.
	 * @param device the current device
	 */
	onDeviceUpdate = (device: Device) => {
		let actions: Array<ProjectorActionType>

		actions = this.getGenericActions()

		if (device instanceof DeviceRPi) actions = actions.concat(this.getRPiActions(device))
		else if (device instanceof DeviceGroup) actions = actions.concat(this.getGroupActions(device))
		else return this.reset() // the actions are only available to physical devices

		this.store.set(actions)
	}

	private getGenericActions(): Array<ProjectorActionType> {
		return ["REBOOT", "STOP_PLAYBACK"]
	}

	private getGroupActions(group: DeviceGroup) {
		let actions: Array<ProjectorActionType> = []

		return actions
	}

	private getRPiActions(device: DeviceRPi) {
		let actions: Array<ProjectorActionType> = []

		if (device.compareVersion("3.4.0") >= 0) actions.push("HDMI_FIX")
		if (
			getCurrentUser().hasPriv("beta_opt_in") &&
			device.compareVersion(device.availableUpdate) > 0
		)
			actions.push("FW_ROLLBACK")

		return actions
	}
}

export const ProjectorActionsController = new ProjActionsCntl()
