<script lang="ts">
	import { Device, DeviceBrowser, DeviceGroup, DeviceRPi } from "luxedo-data"
	import ProjectorAdvancedActionsDefault from "./AdvancedActions_Default.svelte"
	import ProjectorAdvancedActionsGroup from "./AdvancedActions_Group.svelte"

	export let device: Device
</script>

<div class="device-advanced-options">
	{#if device instanceof DeviceRPi}
		<ProjectorAdvancedActionsDefault />
	{:else if device instanceof DeviceGroup}
		<ProjectorAdvancedActionsGroup />
	{/if}
</div>

<style>
	.device-advanced-options :global(.advanced-actions .projector-heading) {
		background-color: var(--color-bg-dark);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		border-radius: var(--br);
		width: 100%;
		padding: 1rem 1rem;
		margin-top: 1rem;
	}

	.device-advanced-options :global(h2) {
		margin: 0;
		color: var(--color-text-light);
		font-size: var(--h1);
	}

	.device-advanced-options :global(.advanced-actions .info) {
		color: var(--color-text);
	}

	.device-advanced-options :global(.heading) {
		width: 100%;
		justify-content: space-between;
	}

	.device-advanced-options :global(.heading button.icon svg) {
		transition: transform 250ms;
	}

	.device-advanced-options :global(.heading.open button.icon svg) {
		transform: rotateX(180deg);
	}

	.device-advanced-options :global(.buttons) {
		padding-top: 1rem;
	}

	.device-advanced-options :global(.buttons button) {
		flex-shrink: 0;
	}

	.device-advanced-options :global(.buttons > .flex-row:not(:last-child)) {
		margin-bottom: 0.5rem;
	}

	.device-advanced-options :global(.buttons > .flex-row .info) {
		margin-left: 1rem;
	}
</style>
