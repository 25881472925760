export class DataSaveError extends Error {
	name = "Data Save Error"
	constructor(error) {
		super(error)
	}
}

export class RollbackFeedbackError extends Error {
	name = "Rollback Feedback"
}
