import {
	DeviceRPi,
	StatusColors,
	type DeviceRPiRawData,
	type DeviceStatusAppearance,
	type StatusRPiDevice,
} from "../"
import type { Timetable } from "../../../json-model"

const serverData: DeviceRPiRawData = {
	name: "Device NEEDS UPDATE",
	password: "iamarealpassword",
	cal_list: [],
	eidos: {
		status: "DORMANT",
	},
	product_id: 6,
	id: 101,
	status: "DORMANT",
	ui_color: 15,
	type_id: "dev_luxcast",
	version_avail: "2.12.3",
	version_crnt: "2.12.0",
	recommended_exposure: 1,
}

export class DeviceNeedsUpdate extends DeviceRPi {
	serverData: DeviceRPiRawData = { ...serverData }
	timetableManager: Timetable = undefined

	constructor() {
		super(serverData)

		console.error("device needs update init")

		setTimeout(() => {
			console.log("now idel")
			this.onEidosUpdate({
				status: "IDLE",
			})
		}, 20000)
	}

	protected importData(data: DeviceRPiRawData): void {
		super.importData(data)

		this.timetableManager = undefined

		console.log(this)
	}

	async update(): Promise<void> {
		setTimeout(() => {
			this.onEidosUpdate({
				status: "OFF",
			})
		}, 1000)
		setTimeout(() => {
			this.onEidosUpdate({
				status: "UPDATING",
			})
		}, 3000)
		setTimeout(() => {
			this.firmwareVersion = this.serverData.version_avail
			this.onEidosUpdate({
				status: "IDLE",
			})
		}, 10000)
	}

	async platoCall(fnName: unknown, fnArgs: unknown): Promise<any> {
		return
	}
}
