import {
	DataHandlerDevice,
	Device,
	DeviceRPi,
	Version,
	type EidosFirmware,
	type EidosTypeOf,
} from "luxedo-data"
import { openUpdateInformerOverlay } from "../../comps/reusable/overlays/device-update"
import { getCurrentUser } from "../../stores/UserStore"
import { DateTime } from "luxon"

export namespace DeviceUpdateManager {
	let deviceList: Array<number>

	// key: device ID, value: update listener ID
	let deviceUpdateIdMap: { [index: number]: string } = {}

	// key: device ID, value:
	let informedMap: { [index: number]: boolean } = {}

	/**
	 * Called when the device received an eidos update. If an update is available, open the update overlay.
	 */
	function onDeviceUpdate(device: Device) {
		if (!device.isOnline) return
		const user = getCurrentUser()
		const isUserBeta = user.hasPriv("beta_opt_in")

		if (device.isUpdateAvailable(isUserBeta)) {
			if (!informedMap[device.id]) {
				const [updateVer, isUpdateInBeta] = getAvailableUpdate(device as DeviceRPi)

				openUpdateInformerOverlay(device, updateVer, isUpdateInBeta)
			}
			informedMap[device.id] = true
		}

		const updateId = deviceUpdateIdMap[device.id]
		if (updateId) device.removeUpdateListener(updateId)
	}

	/**
	 * Converts a list of devices to a list of device IDs
	 */
	function convertDevicesToIdArray(devices: Array<Device>) {
		return devices.map((dev) => dev.id).sort((a, b) => a - b)
	}

	/**
	 * Clear all eidos listeners
	 */
	function clearListeners() {
		for (const [devId, updateId] of Object.entries(deviceUpdateIdMap)) {
			DataHandlerDevice.get(Number(devId))?.removeUpdateListener(updateId)
		}
	}

	/**
	 * Initializes update listeners
	 */
	export function initialize() {
		const allDevices = DataHandlerDevice.getMany()
		deviceList = convertDevicesToIdArray(allDevices)

		for (const device of allDevices) {
			let updateId = device.addUpdateListener(onDeviceUpdate)
			deviceUpdateIdMap[device.id] = updateId
		}
	}

	export async function triggerUpdate(device: Device): Promise<void> {
		const user = getCurrentUser()
		const isUserBeta = user.hasPriv("beta_opt_in")

		return new Promise((res, rej) => {
			device.update()
			// temporarily disable eidos updates until updating kicks in
			// this should improve responsiveness on the frontend when triggering an update operation
			device.overrideEidos(
				// @ts-ignore
				{ status: "UPDATING", playback_type: "EMPTY", __TEMP__: true },
				180,
				(eidos: EidosFirmware, dev: DeviceRPi) =>
					eidos.status === "UPDATING" || !dev.isUpdateAvailable(isUserBeta)
			)

			let timestart = DateTime.now()
			let interval = setInterval(async () => {
				if (Math.abs(DateTime.now().diff(timestart).as("seconds")) > 180) {
					clearInterval(interval)
					return rej()
				}

				await DataHandlerDevice.pull([device.id])
				const dev = DataHandlerDevice.get(device.id)

				if (!dev.isUpdateAvailable(isUserBeta)) {
					clearInterval(interval)
					return res()
				}
			}, 2500)
		})
	}

	/**
	 * Returns the available update and if the update is in beta
	 */
	export function getAvailableUpdate(device: DeviceRPi): [string, boolean] {
		const isUserBeta = getCurrentUser().hasPriv("beta_opt_in")

		// If availableUpdateBeta is the same as the availableUpdate, the beta update is not actually beta
		const updateBeta = new Version(device.availableUpdateBeta)
		const updateStable = new Version(device.availableUpdate)

		const isUpdateInBeta = updateBeta.is_after(updateStable)
		const updateVer = isUserBeta ? updateBeta : updateStable

		return [updateVer.str, isUpdateInBeta]
	}

	// If a device is added or removed, re-initialize the listeners above
	DataHandlerDevice.addListener(() => {
		if (!deviceList || deviceList.length === 0) return

		const userDevices = DataHandlerDevice.getMany()
		const newDeviceList = convertDevicesToIdArray(userDevices)
		if (!compareArraysAreEqual(newDeviceList, deviceList)) {
			clearListeners()
			initialize()
		}
	})
}

function compareArraysAreEqual(listA: Array<number>, listB: Array<number>) {
	return JSON.stringify(listA.sort()) === JSON.stringify(listB.sort())
}
