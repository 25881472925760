import { DataHandler, type BundleOf, type IDMap } from "data-handler"
import { Lightshow, type LightshowRawData } from "../entries/Lightshow"
import { LuxedoRPC } from "luxedo-rpc"
import { DataHandlerFile } from "./DataHandlerFile"
import { Device } from "../entries/Device"
import { Tag } from "../entries/Tag"
import { DataHandlerDevice } from "./DataHandlerDevice"

const TRUE = 1
const FALSE = 0

class DHLightshow extends DataHandlerFile<Lightshow> {
	EntryClass = Lightshow

	protected async fetch(specificInstances?: number[] | undefined): Promise<BundleOf<Lightshow>> {
		return await LuxedoRPC.api.bundlers.lightshow_bundle_down(specificInstances ?? [])
	}

	protected async pushData(entryData: { [id: number]: Partial<LightshowRawData> }): Promise<void> {
		return await LuxedoRPC.api.bundlers.lightshow_bundle_up(entryData as IDMap<LightshowRawData>)
	}

	protected async requestCreateEntry(entryData: Partial<LightshowRawData>): Promise<number> {
		const { name, parent_id, target_device_id, res_x, res_y } = entryData
		if (!name) throw new Error("No name when creating lightshow")

		const { new_file_id } = await LuxedoRPC.api.lightshow.lightshow_create(
			name,
			parent_id!,
			{
				w: res_x,
				h: res_y,
			},
			target_device_id!
		)

		return new_file_id
	}

	protected async requestDeleteEntry(entry: Lightshow): Promise<void> {
		return await LuxedoRPC.api.lightshow.lightshow_delete(entry.id!)
	}

	async save(entry: Lightshow): Promise<void> {
		if (!entry.id)
			throw new Error("Lightshow entry has not been created on the server, cannot save.")

		const device = DataHandlerDevice.get(entry.target_device_id)
		if (device.compareVersion("3.2.0") < 0)
			await LuxedoRPC.api.lightshow.lightshow_save_and_render(entry.id!, {
				blocks: entry.blocks,
				sequence: entry.sequence,
			})
		else
			await LuxedoRPC.api.lightshow.lightshow_save_json(entry.id!, {
				blocks: entry.blocks,
				sequence: entry.sequence,
			})

		return await this.push([entry])
	}

	public async rename(objectToRename: Lightshow, newName: string): Promise<void> {
		await LuxedoRPC.api.filesys.fobj_rename(objectToRename.id!, TRUE, newName)
		await this.pull([objectToRename.id!])
		this.ondatachanged([objectToRename.id!])
	}

	public async move(objectToMove: Lightshow, moveTo: number): Promise<void> {
		console.error("Move is not implemented within DataHandlerLightshow.")
	}

	public async toggleTag(design: Lightshow, tag: Tag) {
		if (design.tags.includes(tag.id!)) {
			await LuxedoRPC.api.tag.tag_project_remove(tag.id!, design.id!)
			design.tags.splice(design.tags.indexOf(tag.id!), 1)
		} else {
			await LuxedoRPC.api.tag.tag_project_add(tag.id!, design.id!)
			design.tags.push(tag.id!) // this might not be a good practice, but in theory it should work
		}
		this.ondatachanged([design.id!])
	}

	getByDevice(device: Device | number) {
		const devId = device instanceof Device ? device.id : device
		return this.getMany(
			undefined,
			(scene) => scene.target_device_id === devId && scene.name !== "__temp_ls__"
		)
	}
}

export const DataHandlerLightshow = new DHLightshow()
