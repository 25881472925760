<script lang="ts">
	import { DeviceRPi, ProjectorPowerManager, type Device } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../../../stores/SelectedDeviceStore"
	import {
		AdvancedSettingsController,
		type AdvancedSettingProperty_Name,
	} from "../ProjectorAdvancedController"
	import PowerButton from "../../../../../reusable/icons/PowerButton.svelte"
	import { Toast } from "svelte-comps/toaster"
	import { ThirdPartyProjectorManager } from "luxedo-data"
	import { ToggleSwitch } from "svelte-comps/inputs"
	import { tooltip } from "svelte-comps/tooltip"
	import type { Unsubscriber } from "svelte/store"

	let device: Device
	let availableProperties: AdvancedSettingProperty_Name[]

	let projectorPower: "ON" | "OFF" | "POWERING_ON" | "POWERING_OFF" | "UNDEFINED"
	let isPowerChanging: boolean = false

	let activeResolution: string

	let isTimeoutActive: boolean
	let timeoutDuration: number

	let unsubscriber: Unsubscriber
	SelectedDeviceStore.subscribe((dev) => {
		device = dev
		availableProperties = AdvancedSettingsController.getAvailableProperties(device)

		if (unsubscriber) unsubscriber()
		if (device) unsubscriber = ProjectorPowerManager.subscribeTo(device.id, onPowerStatusChange)
	})

	AdvancedSettingsController.subscribe((ctx) => {
		if (!ctx.isOnline) return

		activeResolution = ctx.resolution
		isTimeoutActive = ctx.isTimeoutActive
		timeoutDuration = ctx.timeoutDuration
	})

	function onPowerStatusChange(status: {
		state: "ON" | "OFF" | "POWERING_ON" | "POWERING_OFF" | "UNDEFINED"
		isChanging: boolean
	}) {
		projectorPower = status.state
		isPowerChanging = status.isChanging
	}

	/**
	 * Ensures this device is a luxcast
	 */
	function isLuxcast(dev: Device): dev is DeviceRPi {
		return dev && dev.hasConnectedProjector
	}

	/**
	 * Turns the internal projector on or off (depending on current state)
	 */
	async function toggleProjectorPower() {
		let newPower: "ON" | "OFF"
		if (projectorPower === "ON") {
			newPower = "OFF"
		} else {
			newPower = "ON"
		}

		if (isLuxcast(device)) {
			try {
				Toast.text(`Powering projector ${newPower.toLowerCase()}`)
				await ProjectorPowerManager.setPower(device, newPower)
				Toast.success(`The projector has powered ${newPower.toLowerCase()}`)
			} catch (e) {
				console.error("Unable to modify projector power state.")
				Toast.error(`Unable to power ${newPower.toLowerCase()} the projector`)
			}
		}
	}

	/**
	 * Called when the user toggles the projector timeout slider
	 * @param newVal boolean - activate or deactivate projector timeout
	 */
	async function onToggleProjectorTimeout(newVal: boolean) {
		isTimeoutActive = newVal
		let newDuration
		if (!newVal) {
			newDuration = 0
		} else {
			newDuration = AdvancedSettingsController.getInitialPropertyValue(device, "timeoutDuration")
		}
		AdvancedSettingsController.modifyProperty("timeoutDuration", newDuration)
		timeoutDuration = newDuration
		isTimeoutActive = newVal
	}

	/**
	 * Called when the user changes the value of the projector timeout (should only be triggered while the timeout is active)
	 * @param newTimeout the new timeout duration
	 */
	async function onProjectorTimeoutUpdate(newTimeout: number) {
		AdvancedSettingsController.modifyProperty("timeoutDuration", newTimeout)
		timeoutDuration = newTimeout
	}

	/**
	 * Called when the user changes which resolution is selected in the resolution dropdown.
	 * Does not push change to server
	 * @param e the select event
	 */
	function onResolutionInput(
		e: Event & {
			currentTarget: EventTarget & HTMLSelectElement
		}
	) {
		AdvancedSettingsController.modifyProperty("resolution", e.currentTarget.value)
		activeResolution = e.currentTarget.value
	}
</script>

<div class="flex-row projector-heading">
	<h2 class="setting-heading">Projector</h2>

	{#if availableProperties.includes("powerStatus")}
		<PowerButton
			isOn={projectorPower === "ON"}
			togglePower={toggleProjectorPower}
			isChanging={isPowerChanging}
		/>
		<span class="power-control-info"
			>{ProjectorPowerManager.convertToReadableState(projectorPower)}</span
		>
	{/if}
</div>

{#if availableProperties.includes("resolution")}
	<div class="select-container">
		<label id="projector-resolution-label" for="resolution-input">Projector Resolution: </label>
		<select id="resolution-input" on:change={onResolutionInput} value={activeResolution}>
			{#each Object.entries(ThirdPartyProjectorManager.getSupportedResolutions()) as [name, res], i}
				<option value={name}>{res.width} x {res.height}</option>
			{/each}
		</select>
	</div>
{/if}

{#if availableProperties.includes("isTimeoutActive")}
	<div class="flex-row projector-timeout-container">
		<div class="switch-container">
			<span
				class="label projector-timeout"
				use:tooltip={{
					content:
						"The duration between the last active projection and the projector's automatic shutdown to conserve energy.",
					pointing: "top",
				}}
				>Projector Timeout:
			</span>
			<ToggleSwitch
				isActive={isTimeoutActive}
				onUpdate={onToggleProjectorTimeout}
				info={"Toggle the projector timeout. If it is disabled, the internal projector will remain powered on."}
			/>
		</div>
		{#if isTimeoutActive}
			<input
				type="number"
				name=""
				id=""
				bind:value={timeoutDuration}
				on:change={(e) => onProjectorTimeoutUpdate(Number(e.currentTarget.value))}
			/>
			min
		{/if}
	</div>
{/if}

<style>
	.flex-row.projector-heading .setting-heading {
		margin-right: 0.5rem;
	}
</style>
