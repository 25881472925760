import {
	Scene,
	type FullCalendarEvent,
	Lightshow,
	DataHandlerScene,
	Device,
	DataHandlerLightshow,
	DataHandlerDevice,
} from "luxedo-data"
import { closeOverlay, openOverlay } from "svelte-comps/overlay"
import { Controller } from "svelte-comps/stores"
import ShowOverview from "./ShowOverview.svelte"
import { Toast } from "svelte-comps/toaster"

class ShowOverviewCtrl extends Controller<{
	show: Scene | Lightshow
	device: Device
	event?: FullCalendarEvent
	newDeviceId?: number
	isReuploading: boolean
	isPreviewing: boolean
}> {
	overlayID: string

	sceneListeners = []
	lightshowListeners = []

	constructor() {
		super({
			show: undefined,
			device: undefined,
			isReuploading: false,
			isPreviewing: false,
		})
	}

	// #region    ========================== Component Operations ==========================

	/**
	 * Shows the reupload form
	 */
	showReuploadForm = () => {
		this.update({ isReuploading: true })
	}

	/**
	 * Opens the show "preview" video player
	 */
	showVideoPlayer = () => {
		this.update({ isPreviewing: true })
	}

	/**
	 * Closes the show "preview" video player
	 */
	hideVideoPlayer = () => {
		this.update({ isPreviewing: false })
	}

	// #endregion ========================== Component Operations ==========================
	// #region    ==========================  Overlay Management  ==========================

	/**
	 * Opens ShowOverview in an overlay (as intended), saving the overlay ID
	 */
	async open(show: Scene | Lightshow, event?: FullCalendarEvent) {
		if (!show)
			return Toast.error(
				"This show cannot be viewed. It may have been deleted or is owned by another user."
			)

		if (show instanceof Scene && !show.render_ver) {
			await DataHandlerScene.pull([show.id])
			show = DataHandlerScene.get(show.id)
		}

		let device = DataHandlerDevice.get(show.target_device_id)

		this.reset()
		this.update({
			show,
			event,
			device,
		})

		this.listenToShowUpdates(show)

		this.overlayID = openOverlay(ShowOverview, {
			classOverlay: "new-scene-overlay",
			classHeading: "no-underline no-pad",
		})
	}

	reset() {
		super.reset()
		this.clearListeners()
	}

	/**
	 * Closes the overlay
	 */
	close() {
		closeOverlay(this.overlayID)
	}

	// #endregion ==========================  Overlay Management  ==========================
	// #region 		==========================    Data Interface    ==========================

	doUpdateAll: boolean // if true, update all scenes with no device to the specfified device

	/**
	 * Sets the new device Id to the specified device (used when calling saveChanges below)
	 * @param deviceId the new device ID
	 */
	setDevice(deviceId: number) {
		this.update({
			newDeviceId: deviceId,
		})
	}

	/**
	 * Sets the "doUpdateAll" property - this informs the saveChanges method whether to update ALL unassigned scenes with the device id set in setDevice (see above)
	 * @param doUpdateAll
	 */
	setDoUpdateAllScenes(doUpdateAll: boolean) {
		this.doUpdateAll = doUpdateAll
	}

	/**
	 * Updates the assigned device for this show and if doUpdateAllScenes was set to true, will assign all shows with the same initial target device.
	 * @returns "one" if only one scene was modified, "many" if multiple scenes were modififed
	 */
	saveChanges = async (): Promise<"one" | "many"> => {
		const newDeviceId = this.get().newDeviceId
		const show = this.get().show

		if (!newDeviceId) return

		const updateThisScene = async () => {
			show.target_device_id = newDeviceId

			if (show instanceof Lightshow) await DataHandlerLightshow.save(show)
			else await DataHandlerScene.save(show)
		}

		const updateAllScenes = async () => {
			const initialDeviceId = show.target_device_id

			const updatedScenes: Array<Scene> = []
			const updatedLightshows: Array<Lightshow> = []

			const allScenes = DataHandlerScene.getByDevice(initialDeviceId)
			const allLightshows = DataHandlerLightshow.getByDevice(initialDeviceId)

			for (const scene of allScenes) {
				const currentDevice = DataHandlerDevice.get(scene.target_device_id)
				if (currentDevice) continue // if device is returned by the get above, we don't need to update this scene's device

				scene.target_device_id = newDeviceId
				updatedScenes.push(scene)
			}

			for (const lightshow of allLightshows) {
				const currentDevice = DataHandlerDevice.get(lightshow.target_device_id)
				if (currentDevice) continue

				lightshow.target_device_id = newDeviceId
				updatedLightshows.push(lightshow)
			}

			if (updatedScenes.length) await DataHandlerScene.push(updatedScenes)
			if (updatedLightshows.length) await DataHandlerLightshow.push(updatedLightshows)
		}

		if (this.doUpdateAll) {
			await updateAllScenes()
		} else {
			await updateThisScene()
		}

		let updatedShow =
			show instanceof Scene ? DataHandlerScene.get(show.id) : DataHandlerLightshow.get(show.id)
		this.update({
			show: updatedShow,
			device: DataHandlerDevice.get(newDeviceId),
		})

		return this.doUpdateAll ? "many" : "one"
	}

	// #endregion ==========================    Data Interface    ==========================
	// #region 		========================== 			Listeners				==========================

	clearListeners() {
		for (const listener of this.sceneListeners) {
			DataHandlerScene.removeListener(listener)
		}
		for (const listener of this.lightshowListeners) {
			DataHandlerLightshow.removeListener(listener)
		}
	}

	listenToShowUpdates(show: Scene | Lightshow) {
		this.clearListeners()
		if (show instanceof Scene) {
			DataHandlerScene.addListener(async (ids) => {
				if (ids.includes(show.id)) {
					this.update({
						show: DataHandlerScene.get(show.id),
					})
				}
			})
		} else if (show instanceof Lightshow) {
			DataHandlerLightshow.addListener(async (ids) => {
				if (ids.includes(show.id)) {
					this.update({
						show: DataHandlerLightshow.get(show.id),
					})
				}
			})
		}
	}

	// #endregion ========================== 			Listeners				==========================
}

export const ShowOverviewController = new ShowOverviewCtrl()
