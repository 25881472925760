import { Entry } from "data-handler"
import { v4 as uuid } from "uuid"

import { Scene, type SceneRawData } from "./Scene"
import { DataHandlerScene } from "../datahandlers/DataHandlerScene"
import type { Tag } from "./Tag"
import { DataHandlerLightshow } from "../datahandlers/DataHandlerLightshow"
import { DateTime } from "luxon"

type SrcKeys = "audio" | "compressed" | "directUpload" | "editor" | "renderData" | "thumbnail" | "video"

export class Lightshow extends Entry<LightshowRawData> {
	declare name: string
	declare res_x: number
	declare res_y: number
	declare updated_at?: Date
	declare render_ver?: Date
	declare target_device_id?: number
	declare owner_id?: number
	declare parent_id?: number
	declare duration: number
	declare description?: string
	declare tags: Array<number>
	declare isDirectUpload
	declare size: number

	declare blocks: { [index: string]: LightshowBlock_Break | LightshowBlock_Scene }
	declare sequence: string[]

	declare src: { [key in SrcKeys]: string | undefined }

	protected exportData(): Partial<LightshowRawData> {
		return {
			name: this.name,
			assigned_device_id: this.target_device_id,
			res_x: this.res_x,
			res_y: this.res_y,
			duration: this.duration,
		}
	}

	protected importData(data: LightshowRawData): void {
		this.name = data.name
		this.res_x = data.res_x
		this.res_y = data.res_y
		this.updated_at = data.updated_at ? DateTime.fromSQL(data.updated_at, { zone: "utc" }).toJSDate() : undefined
		this.render_ver = data.render_ver ? DateTime.fromSQL(data.render_ver, { zone: "utc" }).toJSDate() : undefined
		this.target_device_id = data.assigned_device_id
		this.duration = Number(data.output_duration ?? "0")
		this.tags = []
		this.size = data.size_kb ?? 0

		this.blocks = data.json_data.blocks
		this.sequence = data.json_data.sequence
		this.isDirectUpload = false // added to allow lightshows and scenes to be used interchanably

		this.src = {
			audio: data.srcA,
			compressed: data.srcC,
			directUpload: data.srcD,
			editor: data.srcET,
			renderData: data.srcR,
			thumbnail: data.srcT,
			video: data.srcVA,
		}
	}

	/**
	 * Recreates the blocks and sequence properties according to the passed array
	 */
	updateBlocksFromList(list: Array<Scene | BreakConfig>) {
		let newBlocks: { [index: string]: LightshowBlock_Break | LightshowBlock_Scene } = {}
		let newSequence: Array<string> = []

		let index = 0

		let lastBlockID: number | undefined = undefined
		let lastSceneID: number | undefined = undefined

		for (const block of list) {
			let id = this.generateNewID()
			if (block instanceof Scene) {
				if (lastBlockID && lastSceneID === block.id) {
					;(newBlocks[lastBlockID].config as SceneConfig).repeat++
				} else {
					lastSceneID = block.id
					lastBlockID = id

					newBlocks[id] = {
						id,
						type: "scene",
						config: {
							fade_in: 0,
							fade_out: 0,
							repeat: 1,
							scene_id: block.id ?? -9999,
						},
					}

					newSequence.push(id)
				}
			} else {
				newBlocks[id] = {
					id,
					type: "padding",
					config: {
						duration: block.duration,
					},
				}
				newSequence.push(id)
			}
			index++
		}

		this.blocks = newBlocks
		this.sequence = newSequence
	}

	getScenesInOrder() {
		return Lightshow.toContentSequence({
			blocks: this.blocks,
			sequence: this.sequence,
		})
	}

	/**
	 * Returns an object - keys are the relative start times of each scene within the lightshow with values being the block (scene or break)
	 */
	getScenesAndTimestamps(): { [index: number]: Scene | BreakConfig } {
		const timestamps = {}
		const scenes = this.getScenesInOrder()

		let currentTime = 0
		scenes.forEach((scene) => {
			timestamps[currentTime] = scene
			currentTime += scene.duration
		})

		return timestamps
	}

	generateNewID() {
		return uuid()
	}

	public async toggleTag(tag: Tag) {
		return await DataHandlerLightshow.toggleTag(this, tag)
	}

	/**
	 * Calculate the total duration of this lightshow based on the sum of the block durations
	 * Note that scenes use the duration of their LATEST RENDERED VIDEO, not of their json metadata.
	 * @returns
	 */
	calculateDuration(): number {
		let duration = 0
		for (const block of this.getScenesInOrder()) {
			duration += block.duration
		}
		return duration
	}

	/**
	 * Get an array containing one of each unique scene present in this lightshow
	 */
	getUniqueScenes(): Array<Scene> {
		const allBlocks = this.getScenesInOrder()
		const uniqueScenes = new Set(allBlocks.filter((block) => block instanceof Scene))
		return Array.from(uniqueScenes) as Array<Scene>
	}

	static hashBlockSequence(seq: LightshowContentSequence): string {
		let hashstr = ""
		for (const block of seq) {
			hashstr += block instanceof Scene ? `s${block.id}` : `b${block.duration}`
		}
		return hashstr
	}

	static toContentSequence(json_data: LightshowRawData["json_data"]): LightshowContentSequence {
		const scenes: LightshowContentSequence = []
		if (!json_data.sequence) return []
		for (const id of json_data.sequence) {
			const block = json_data.blocks[id]
			let scene
			if (block.type === "scene") {
				scene = DataHandlerScene.get(block.config.scene_id)
				for (let i = 0; i < block.config.repeat; i++) scenes.push(scene)
			} else {
				scene = new LightshowBreak(block.config.duration)
				scenes.push(scene)
			}
		}
		return scenes
	}

	/**
	 * @override
	 * Check if the lightshow has changed
	 * Also checks if the scene order has changed
	 */
	get dirty(): boolean {
		if (super.dirty) return true

		const currentContentsHash = Lightshow.hashBlockSequence(this.getScenesInOrder())
		const originalContentsHash = Lightshow.hashBlockSequence(Lightshow.toContentSequence(this.readModel("json_data")))
		return currentContentsHash != originalContentsHash
	}
}

export type LightshowContentSequence = Array<Scene | LightshowBreak>

interface BreakConfig {
	duration: number
}
export class LightshowBreak implements BreakConfig {
	public duration: number // The duration of the break (in seconds)

	constructor(minutes: number, seconds: number)
	constructor(duration: number)
	constructor(min: number, sec?: number) {
		if (sec !== undefined) {
			this.duration = min * 60 + sec
		} else {
			this.duration = min
		}
	}

	get min() {
		return Math.floor(this.duration / 60)
	}

	get sec() {
		return this.duration % 60
	}
}

type SceneConfig = {
	fade_in: 0
	fade_out: 0
	repeat: number
	scene_id: number
}

type LightshowBlock_Scene = {
	id: string
	type: "scene"
	config: SceneConfig
}

type LightshowBlock_Break = {
	id: string
	type: "padding"
	config: BreakConfig
}

export interface LightshowRawData extends SceneRawData {
	name: string
	json_data: {
		blocks: {
			[index: string]: LightshowBlock_Break | LightshowBlock_Scene
		}
		sequence: Array<string>
	}
}
