import { Entry, type EntryRawData } from "data-handler"
import { LuxedoRPC } from "luxedo-rpc"
import { v4 as uuid } from "uuid"
import { DataHandlerSnapshot } from "../../datahandlers/DataHandlerSnapshot"
import { DataHandlerDevice } from "../../datahandlers/DataHandlerDevice"
import type { Snapshot } from "../Snapshot"
import type { Scene } from "../Scene"
import type { Lightshow } from "../Lightshow"
import { Timetable } from "../../json-model/timetable/Timetable"
import {
	DevOpsError,
	type DeviceTypeName,
	type EidosTypeOf,
	PROJECTOR_COLOR_OPTIONS,
	TYPE_NAMES,
	type BaseEidosType,
	type DeviceStatusAppearance,
	type DeviceRawData,
	type AnyDevice,
	DeviceBrowser,
	DeviceRPi,
	DeviceGroup,
	CalibrationError,
	type ProjectAndCaptureState,
	CALIBRATION_ERROR_CODES,
	type ProjectAndCaptureError,
	CALIBRATION,
	CalibrationFailure,
	type AdvancedCalibrationOptions,
} from "."
import type { TrackedCalibrationData } from "../../json-model/calibration/CalibrationInstance"
import { DataHandlerCalibration } from "../../datahandlers/DataHandlerCalilbration"
import {
	type SingleEventTiming,
	TimetableEventSingle,
	TEMPORARY_PLAY_NOW_EVENT_NAME,
} from "../../json-model"
import { DateTime } from "luxon"
import { DataHandlerScene } from "../../datahandlers/DataHandlerScene"
import { ProjectorPowerManager } from "../../modules/device-operation-managers/ProjectorPowerManager"

export interface BaseDeviceRawData extends EntryRawData {
	name: string
	type_id: DeviceTypeName
	orientation?: string
	status: string
	proj_id?: number
	timezone?: string
	audio_delay?: number
	audio_code?: string
	auto_sync?: number
	default_snap?: number
	cal_list: Array<TrackedCalibrationData>
	recommended_exposure: number
	ui_color: number
	active_calibration_id?: number
	group_id?: number
	eidos: BaseEidosType
}

export interface Device<T extends BaseDeviceRawData = BaseDeviceRawData> extends Entry<T> {
	name: string
	typeId: DeviceTypeName
	_status: string
	resX: number
	resY: number
	defaultSnap?: number
	audioCode?: string
	_eidos: EidosTypeOf<T>
	_color: number
	_rawData: T

	password?: string
	isResolutionChanging?: boolean
	firmwareVersion?: string
	thirdPartyProjector?: string

	timetableManager: Timetable

	/**
	 * Get the eidos of the device
	 */
	getEidos(): EidosTypeOf<T>

	/**
	 * Update the eidos and trigger any event listeners
	 * @param eidos
	 */
	onEidosUpdate(eidos: EidosTypeOf<T>): void

	/**
	 * Register a new callback to fire whenever the eidos changes
	 * @param updater
	 * @returns A listener ID that can be used to deregister it with removeUpdateListener
	 */
	addUpdateListener(updater: (device: Device) => void): string

	/**
	 * Deregister an eidos listener
	 * @param id The ID of the eidos listener (returned by addUpdateListener)
	 */
	removeUpdateListener(id: string): void

	/**
	 * Tell the device to capture a snapshot, finally resolving once it is done
	 * Assumes the device is online
	 */
	captureSnapshot(calibrationID?: number): Promise<number>

	/**
	 * Call a plato function on the backend
	 * Resolves once the backend has acknowledged the message - does NOT assure that the command succeeded
	 * @param fnName The function name
	 * @param fnArgs And array of args to pass into the function
	 */
	platoCall(fnName: string, fnArgs: Array<any>): Promise<any>

	/**
	 * Tell the device to stop playing whatever static lightshow/image it is playing
	 * Resolves once the backend has acknowledged the message - does NOT assure that the command succeeded
	 */
	clearPreview(): Promise<void>

	/**
	 * Tell the device to start playing a scene or lightshow
	 * Resolves once the backend has acknowledged the message - does NOT assure that the command succeeded
	 * @param show
	 */
	previewShow(show: Scene | Lightshow): Promise<void>

	/**
	 * Get the parent group of the device, or null if not in one / not applicable
	 */
	getParent(): DeviceGroup | null

	/**
	 * Fetch the device's active snapshot
	 * Resolves with a snapshot entry
	 */
	getSnapshot(): Promise<Snapshot | undefined>

	/**
	 * Get an object containing instructions on how to display a status in the frontend
	 */
	getStatus(): DeviceStatusAppearance

	/**
	 * Gets the status of any pending device update
	 */
	isUpdateAvailable(includeBeta?: boolean): boolean

	/**
	 * Triggers device to update
	 */
	update(): void
}

export type DeviceConstructor = new (rawData: DeviceRawData) => AnyDevice
export const DeviceConstructor = DeviceLoader as any as DeviceConstructor
function DeviceLoader(rawData: DeviceRawData): AnyDevice {
	if (rawData.type_id === "dev_luxedo" || rawData.type_id === "dev_luxcast")
		return new DeviceRPi(rawData)
	if (rawData.type_id === "dev_amalgam" || rawData.type_id === "dev_group")
		return new DeviceGroup(rawData)
	if (rawData.type_id === "dev_synth" || rawData.type_id === "dev_caxedo")
		return new DeviceBrowser(rawData)
	throw TypeError(`Tried to construct a device without a valid type_id? (${rawData.type_id})`)
}

export abstract class Device<T extends BaseDeviceRawData> extends Entry<T> {
	protected statusListeners: {
		[index: string]: Function
	} = {}

	constructor(data: T) {
		super(data)
	}

	protected abstract importResolution(data: T): void

	protected importData(data: T): void {
		this.name = data.name
		this.typeId = data.type_id
		this._status = data.eidos ? data.eidos.status : data.status
		this.audioCode = data.audio_code
		this.defaultSnap = data.default_snap

		this._color = data.ui_color

		this.importResolution(data)
		this._rawData = data
		this._eidos = data.eidos! as EidosTypeOf<T>

		this.timetableManager = new Timetable(this)

		this.onEidosUpdate(data.eidos!)
	}

	protected abstract exportData(): Partial<T>

	protected abstract statusAppearanceMap: Record<EidosTypeOf<T>["status"], DeviceStatusAppearance>

	// #region ================= DevOps =================

	public getEidos(): EidosTypeOf<T> {
		if (this.eidos) return this.eidos
		return this._rawData.eidos as EidosTypeOf<T>
	}

	private tempEidos: EidosTypeOf<T> = undefined
	private tempEidosTimeout: number = undefined
	private tempEidosCallback: (eidos: EidosTypeOf<T>, dev?: typeof this) => boolean = undefined

	/**
	 * Temporarily disables eidos updates until a certain duration or until a conditional is met
	 * @param eidos the temporary value of eidos to lock this device's state to
	 * @param timeoutDuration the duration of time before the lock expires and this device will update from the server's data again
	 * @param conditionalCallback a fn which takes the device's acutal eidos value - if true, the eidos lock will be removed
	 */
	public overrideEidos(
		eidos: EidosTypeOf<T>,
		timeoutDuration: number = 180,
		conditionalCallback: (eidos: EidosTypeOf<T>) => boolean = undefined
	) {
		this.tempEidos = eidos
		this.tempEidosCallback = conditionalCallback

		if (this.tempEidosTimeout) clearTimeout(this.tempEidosTimeout)
		this.tempEidosTimeout = window.setTimeout(() => {
			this.removeEidosOverride()
		}, timeoutDuration * 1000)

		this.triggerStatusCallbacks()
	}

	/**
	 * Removes temp eidos override (see disableEidosUpdate)
	 */
	private removeEidosOverride() {
		if (this.tempEidosTimeout) clearTimeout(this.tempEidosTimeout)

		this.tempEidos = undefined
		this.tempEidosCallback = undefined
		this.tempEidosTimeout = undefined
	}

	public onEidosUpdate(eidos: any) {
		this._eidos = { ...eidos }
		if (eidos.status) this._status = eidos.status

		if (this.statusListeners) {
			// wrapped in settimeout to ensure it is called after any subclass's onEidosUpdate logic
			setTimeout(() => {
				this.triggerStatusCallbacks()
			})
		}

		if (this.tempEidosCallback && this.tempEidosCallback(eidos, this)) {
			this.removeEidosOverride()
			setTimeout(() => {
				this.triggerStatusCallbacks()
			}, 1000)
		}
	}

	/**
	 * Calls each status listener with this device's data
	 */
	private triggerStatusCallbacks() {
		Object.values(this.statusListeners).forEach((fn) => {
			fn(this)
		})
	}

	/**
	 * Add a status update listener to be called on device updates
	 * @param updater the fn to be called on a device update
	 * @returns the id of the update listener
	 */
	public addUpdateListener(updater: (device: Device) => void): string {
		const id = uuid()
		this.statusListeners[id] = updater
		updater(this)
		return id
	}

	/**
	 * Removes an update listener added in addUpdateListener
	 * @param id the id of the listener
	 * @returns void
	 */
	public removeUpdateListener(id: string) {
		if (!id) return
		if (!(id in this.statusListeners)) {
			return console.error(`${id} cannot be found in device status listeners`)
		}
		delete this.statusListeners[id]
	}

	/**
	 * Captures a snapshot.
	 * @param calibrationID the calibration ID to generate the new snapshot
	 * @returns the new snapshot ID
	 */
	public async captureSnapshot(calibrationID?: number): Promise<number> {
		const unbindEndpoints = () => {
			LuxedoRPC.bindEndpoint("snapshot_take_complete", undefined)
			LuxedoRPC.bindEndpoint("snapshot_take_fail", undefined)
		}

		return new Promise((res, rej) => {
			try {
				if (!this.id) return undefined
				if (!calibrationID) calibrationID = DataHandlerCalibration.filterByDevice(this)[0].id!

				LuxedoRPC.addCustomData("maintain_control_of", this.id)

				LuxedoRPC.bindEndpoint("snapshot_take_complete", async (snapshot: string) => {
					const snapshotUrlSplit = snapshot.split("/")
					const snapshotIdString = snapshotUrlSplit[snapshotUrlSplit.length - 1]
					const snapshotId = Number(snapshotIdString.split("S.jpg")[0])

					await DataHandlerSnapshot.pull([snapshotId])

					unbindEndpoints()
					res(snapshotId)
				})

				LuxedoRPC.bindEndpoint("snapshot_take_fail", (reason: string) => {
					unbindEndpoints()
					rej(reason)
				})

				LuxedoRPC.api.all.devops_snapshot(this.id, calibrationID)
			} catch (e) {
				unbindEndpoints()
				rej(new DevOpsError("Failed to capture snapshot.", e))
			}
		})
	}

	/**
	 * Return true if spotlight is active
	 * @returns
	 */
	public isSpotlightActive() {
		const eidos = this.getEidos()
		return eidos.display_mode === "STATIC" && eidos.playback_type === "UNKNOWN"
	}

	/**
	 * Activates the device's spotlight feature
	 */
	public async activateSpotlight(): Promise<void> {
		await LuxedoRPC.api.deviceControl.devops_spotlight(this.id!, 600)
		let listenerId
		return new Promise((res, rej) => {
			setTimeout(rej, 60000) // Will timeout after one minute

			listenerId = this.addUpdateListener((dev) => {
				if (dev.isOnline && dev.getEidos().display_mode === "STATIC") {
					this.removeUpdateListener(listenerId)
					res()
				}
			})
		})
	}

	/**
	 * Deactivates the device's spotlight feature
	 */
	public async deactivateSpotlight(): Promise<void> {
		await LuxedoRPC.api.deviceControl.devops_spotlight(this.id!, 0)
		let listenerId
		return new Promise((res, rej) => {
			setTimeout(rej, 60000)

			listenerId = this.addUpdateListener((dev) => {
				if (!dev.isOnline || dev.getEidos().display_mode !== "STATIC") {
					this.removeUpdateListener(listenerId)
					res()
				}
			})
		})
	}

	/**
	 * Calls a plato function for this device
	 */
	public async platoCall(fnName: string, fnArgs: Array<any>) {
		const callFn = async () => {
			const res = await LuxedoRPC.api.plato.plato_call(fnName, fnArgs, this.id!)
			return res
		}

		try {
			return await callFn()
		} catch (e) {
			console.error(`ERROR calling plato fn ${fnName}`, { error: e, fnArgs })

			if (e.statusCode === 700) {
				// indicates device is not controlled by this user
				await LuxedoRPC.api.deviceControl.device_control_aquire(this.id!, 1)
				return await callFn()
			}

			throw e
		}
	}

	/**
	 * Clears any show being previewed on this device
	 */
	async clearPreview() {
		if (this.getEidos().display_mode === "TIMETABLE") return

		// clear any active preview
		await this.platoCall("clear_override", [])

		await this.listenEidosCondition((eidos: EidosTypeOf<T>) => eidos.display_mode === "TIMETABLE")
	}

	/**
	 * Temporary implementation of show preview which uses the timetable to schedule the show for one loop
	 * @param show The show to preview
	 */
	public async previewShow_Temp(show: Scene | Lightshow) {
		let timeBuffer = 10

		const powerState = ProjectorPowerManager.get(this.id).state
		const powerStatesOff = ["OFF", "POWERING_OFF", "UNDEFINED", "POWERING_ON"]
		if (powerStatesOff.includes(powerState)) timeBuffer += 10
		// if (this.getCachedShows().indexOf(show.id) === -1) timeBuffer += 20

		if (!show.duration) await DataHandlerScene.pull([show.id])
		show = DataHandlerScene.get(show.id)

		const start = DateTime.now().plus({
			seconds: timeBuffer,
		})

		const end = start.plus({
			seconds: show.duration,
		})

		const timing: SingleEventTiming = TimetableEventSingle.createTiming(
			start.toISO()!,
			end.toISO()!
		) as SingleEventTiming

		const temporaryEvent = new TimetableEventSingle(
			{
				id: uuid(),
				name: TEMPORARY_PLAY_NOW_EVENT_NAME,
				project_id: show.id!,
				repeat: 0,
				timing: timing,
				priority: 10,
			},
			this
		)

		this.timetableManager.addEvent(temporaryEvent)

		// this is an odd implementation, but it allows for each device to work the same, unless it is a device group which is needed
		if (!(this instanceof DeviceGroup))
			await this.listenEidosCondition(
				(eidos: EidosTypeOf<T>) => eidos.display_mode === "TIMETABLE" && eidos.proj_id === show.id,
				timeBuffer + 45
			)
	}

	/**
	 * Begins previewing a show on this device
	 * @param show the show to preview
	 * @param repeatCount how many times the show will loop
	 */
	public async previewShow(show: Scene | Lightshow, repeatCount = 1, disableSync = false) {
		if (this.eidos && "proj_id" in this.eidos) await this.clearPreview()
		await this.platoCall("play_scene", [show.id, repeatCount, disableSync])

		if (!(this instanceof DeviceGroup)) {
			await this.listenEidosCondition(
				(eidos) => eidos.proj_id === show.id || eidos.player?.id === show.id,
				30
			)
		}
	}

	/**
	 * Return a promise which listens on eidos for a specific condition to be fulfilled
	 * Resolves once the condition is met, and rejects after a timeout, or if the device goes offline
	 * @param condition
	 * @param timeout timeout (in seconds)
	 */
	public async listenEidosCondition(
		condition: (eidos: BaseEidosType) => boolean,
		timeout = 15
	): Promise<void> {
		let listenerId: string
		let timeoutId: any

		return new Promise<void>((res, rej) => {
			const clear = () => {
				this.removeUpdateListener(listenerId)
				clearTimeout(timeoutId)
			}

			const reject = () => {
				clear()
				rej()
			}
			const resolve = () => {
				clear()
				res()
			}

			listenerId = this.addUpdateListener((dev) => {
				if (!dev.isOnline) reject()
				if (condition(dev.getEidos())) resolve()
			})
			timeoutId = setTimeout(reject, timeout * 1000)
		})
	}

	/**
	 * Waits for the device to power on or off
	 * @param powerStatus true if wait for power on false if wait for power off
	 * @param timeout timeout in seconds (defaults to 3 min )
	 * @returns
	 */
	public async awaitPower(powerStatus = true, timeout = 180) {
		let listenerId: string
		let timeoutId: any

		return new Promise<void>((res, rej) => {
			const clear = () => {
				this.removeUpdateListener(listenerId)
				clearTimeout(timeoutId)
			}

			const reject = () => {
				clear()
				rej()
			}
			const resolve = () => {
				clear()
				res()
			}

			listenerId = this.addUpdateListener((dev) => {
				if (dev.isOnline == powerStatus) resolve()
			})
			timeoutId = setTimeout(reject, timeout * 1000)
		})
	}

	/**
	 * Cancels a calibration
	 */
	public async cancelCalibration() {
		await LuxedoRPC.api.deviceControl.devops_cal_cancel(this.id!)
	}

	/**
	 * Triggers this device to begin calibrating, updating the progress via the provided callback.
	 * You probably DON'T want to call this directly! Use instead the DeviceCalibrationManager - that module simplifies the API.
	 * @param progressListener called upon progress updates, providing the new progress and the title of the current step
	 * @throws [DevOpsError] if calibration cannot begin
	 * @throws [CalibrationError] if there is an unrecoverable error while calibrating
	 * @throws [CalibrationFailure] if the calibration failed, a failure error will be thrown with the pncImages
	 */
	public async calibrate(
		progressListener: (data: {
			progress: number
			step: number
			message: string
			description?: string
			pncID?: number
		}) => void,
		advancedCalibrationOptions?: AdvancedCalibrationOptions
	): Promise<Snapshot> {
		let pncID = -1
		let listenerID: string

		let hasStarted = false
		let didError = false

		let currentStep = 0
		let currentProgress = 0

		return new Promise(async (res, rej) => {
			const onCapturingComplete = () => {
				LuxedoRPC.bindEndpoint("device_on_error", undefined)
			}

			const onProcessingComplete = () => {
				LuxedoRPC.bindEndpoint("calibration_calculation_complete", undefined)
				LuxedoRPC.bindEndpoint("calibration_diag_load_cal_imgs", undefined)
				LuxedoRPC.bindEndpoint("snapshot_take_complete", undefined)
				LuxedoRPC.bindEndpoint("calibration_cancelled", undefined)
				LuxedoRPC.bindEndpoint("calibration_progress", undefined)
				onCapturingComplete()

				LuxedoRPC.removeCustomData("maintain_control_of")
				this.removeUpdateListener(listenerID)
			}

			const updateProgress = (
				step: number,
				progress: number,
				message: string,
				description?: string
			) => {
				let offsetStepProgress = CALIBRATION.STEP_WEIGHT[step] * progress
				let totalProgress = CALIBRATION.STEP_OFFSET[step] + offsetStepProgress
				currentStep = step
				currentProgress = progress
				if (step >= CALIBRATION.STEPS.CALCULATING) onCapturingComplete()
				progressListener({
					progress: totalProgress,
					message,
					description,
					step: currentStep,
					pncID,
				})
			}

			const handleError = (
				pncState: ProjectAndCaptureState | ProjectAndCaptureError | undefined,
				info?: {
					message?: string
					code?: string
					description?: string
				}
			) => {
				let errorString = info?.message ?? "Something went wrong..."
				let errorDescription = info?.description ?? undefined
				let errorCode = info?.code ?? CALIBRATION_ERROR_CODES["default"]

				console.error("Calibration error", {
					pncState,
					info,
				})

				let isUnrecoverableError = false

				if (pncState) {
					if ("pnc" in pncState) {
						if (pncState.pnc.info.missing_camera) {
							errorString = "No camera detected..."
							errorDescription =
								"The camera required for calibration is not detected. Ensure the camera is connected, restart your device and try again..."
							errorCode = CALIBRATION_ERROR_CODES.missing_camera
							isUnrecoverableError = true
						}
					} else if (!this.isOnline || (pncState && pncState.state === "LOST")) {
						errorString = "Lost connection with projector... Trying to reconnect..."
						errorDescription =
							"The connection with your device was lost, but don't worry! If your device is still powered on, it may still be calibrating. Working to reconnect now... "
						errorCode = CALIBRATION_ERROR_CODES.device_disconnected
						isUnrecoverableError = false
					} else {
						errorString = "An unknown error occurred..."
						errorDescription =
							"While your device is still connected, the calibration was unable to complete. Please restart your device and try again..."
						errorCode = CALIBRATION_ERROR_CODES.unknown_but_online
						isUnrecoverableError = true
					}
				}

				if (isUnrecoverableError) {
					didError = true
					rej(new CalibrationError(errorString, errorCode, errorDescription))
				} else {
					updateProgress(currentStep, currentProgress, errorString, errorDescription)
				}
			}

			const checkProgress = async () => {
				if (didError) return

				const pncData: ProjectAndCaptureState =
					await LuxedoRPC.api.deviceControl.devops_get_pnc_status(this.id!, pncID ?? -1)

				switch (pncData.state) {
					case "UNKNOWN_ERROR":
					case "LOST":
						return handleError(pncData)
					case "DONE":
						if (pncData.exit_code === "SUCCESS") {
							if (hasStarted)
								return updateProgress(
									CALIBRATION.STEPS.CALCULATING,
									0,
									"Sending images to be processed..."
								)
							else return updateProgress(CALIBRATION.STEPS.SETUP, 0, "Initializing...")
						} else {
							return handleError(pncData)
						}
					case "SEARCHING":
						if (!hasStarted) return updateProgress(CALIBRATION.STEPS.SETUP, 0, "Initializing...")
						else
							return handleError(pncData, {
								message: "Lost connection with projector... Trying to reconnect...",
								code: CALIBRATION_ERROR_CODES["active_to_waiting"],
								description: `The connection with your device was lost, but don't worry! If your device is still powered on, it may still be calibrating. Working to reconnect now...`,
							})
					case "AUTO_EXPOSURE":
						if (!hasStarted)
							return updateProgress(CALIBRATION.STEPS.CAPTURE, 0, "Fine-tuning Camera Settings...")
					case "CAPTURING":
						hasStarted = true
						break
					case "WAITING":
						if (!hasStarted) return
				}

				if (pncData.state != "CAPTURING") {
					return handleError(pncData, {
						code: CALIBRATION_ERROR_CODES.should_be_capturing,
					})
				}

				if (pncData.total == 0) {
					updateProgress(CALIBRATION.STEPS.CAPTURE, 0, "Capturing calibration images...")
				} else if (pncData.captured < pncData.total) {
					const progress = pncData.captured / pncData.total
					updateProgress(
						CALIBRATION.STEPS.CAPTURE,
						progress,
						`Capturing calibration images (${pncData.captured} of ${pncData.total})...`
					)
				} else {
					updateProgress(
						CALIBRATION.STEPS.CALCULATING,
						pncData.total,
						"Sending images to be processed..."
					)
				}
			}

			if (!this.isOnline) return rej(new DevOpsError("Device must be online to calibrate"))
			if (!this.isReady)
				return rej(new DevOpsError("Device is busy. Please try again in a few minutes."))

			LuxedoRPC.bindEndpoint("snapshot_take_complete", async (snapshotUrl: string) => {
				const urlParts: Array<string> = snapshotUrl.split("/")
				const idString = urlParts[urlParts.length - 1].split("S.jpg")[0]
				const snapshotID = Number(idString)

				await DataHandlerSnapshot.pull([snapshotID])
				updateProgress(CALIBRATION.STEPS.COMPLETE, 1, "Calibration Complete")
				onProcessingComplete()

				const snapshot = DataHandlerSnapshot.get(snapshotID)
				res(snapshot)
			})

			LuxedoRPC.bindEndpoint("device_on_error", (deviceID, info) => {
				if (deviceID === this.id!) handleError(info)
			})

			LuxedoRPC.bindEndpoint("calibration_calculation_complete", (isValid, errorCode) => {
				if (!!isValid || errorCode === 0)
					updateProgress(CALIBRATION.STEPS.SNAPSHOT, 0, "Capturing snapshot...")
				else {
					let code = `${CALIBRATION_ERROR_CODES.backend_error_prefix}${(
						errorCode as string
					).padStart(2, "0")}`
					let message = "Unable to finish calibrating..."
					let description =
						"The images captured during the calibration process do not appear to provide the needed information to finish this calibration. If this continues, contact support."

					if (errorCode === 3) {
						message = "Unable to finish calibrating with captured images..."
						description =
							"Not enough of the projection space was captured by the calibration camera. Please ensure the camera and projector are covering the projection space effectively."
					} else if (errorCode === 4) {
						message = "No projection detected..."
						description =
							"No projection was detected by the calibration camera. Please ensure both the camera is facing the projection space and the projector is connected to and receiving the input from your Luxedo device."
					}

					handleError(undefined, {
						message,
						description,
						code,
					})
				}
			})

			LuxedoRPC.bindEndpoint("calibration_diag_load_cal_imgs", (data) => {
				onProcessingComplete()
				const images = Object.values(data) as Array<string>
				console.log({ images })
				rej(
					new CalibrationFailure(
						images,
						"Calibration failed while processing images...",
						"Calibration unable to finish as the captured images did not provide the necessary data. This often happens if the device or camera were moved during this process. Click 'Troubleshoot' to investigate."
					)
				)
			})

			LuxedoRPC.bindEndpoint("calibration_progress", (data) => {
				console.log("Calibration Progress", data)
				updateProgress(CALIBRATION.STEPS.CALCULATING, data, "Processing images...")
			})

			updateProgress(CALIBRATION.STEPS.SETUP, 0, "Initializing...")
			listenerID = this.addUpdateListener(checkProgress)

			LuxedoRPC.addCustomData("maintain_control_of", this.id!)
			await LuxedoRPC.api.deviceControl.device_control_aquire(this.id!, 1)
			if (advancedCalibrationOptions) {
				await LuxedoRPC.api.deviceControl.devops_cal_start_legacy(
					this.id!,
					advancedCalibrationOptions
				)
			} else {
				const { pnc_id } = await LuxedoRPC.api.deviceControl.devops_cal_start(this.id!)
				pncID = pnc_id
			}
		})
	}

	// #endregion ================= DevOps =================
	// #region ==================== Getters ================

	abstract get isOnline(): boolean

	abstract get isReady(): boolean

	get isDeactivated(): boolean {
		return false
	}

	get isCalibrated(): boolean {
		return !!DataHandlerCalibration.filterByDevice(this).filter((cal) => !cal.didFail).length
	}

	get color() {
		return Device.getUIColorByIndex(this._color)
	}

	get type() {
		return Device.getDeviceTypeName(this.typeId)
	}

	get status() {
		return this.getStatus()?.text ?? "Unknown"
	}

	get statusColor() {
		return this.getStatus()?.color
	}

	get isUpdating() {
		if (this.tempEidos && "status" in this.tempEidos) return this.tempEidos.status === "UPDATING"
		return this.status === "UPDATING"
	}

	getParent() {
		if (!(this instanceof DeviceRPi) || !this._rawData.group_id) return null
		return DataHandlerDevice.get(this._rawData.group_id) as DeviceGroup
	}

	public async getSnapshot() {
		if (this.defaultSnap) {
			return DataHandlerSnapshot.get(this.defaultSnap)
		} else {
			const snapshots = DataHandlerSnapshot.filterByDevice(this.id!)

			if (!snapshots.length) return undefined
			return snapshots.reduce((prev, curr) => (prev.datesaved > curr.datesaved ? prev : curr))
		}
	}

	public async setDefaultSnapshot(snapshotID: number) {
		await LuxedoRPC.api.device.set_default_snap(this.id!, snapshotID)
		await DataHandlerDevice.pull([this.id!])
	}

	/**
	 * Gets the readable status object from this device's current status
	 * @returns DeviceStatusAppearance object
	 */
	public getStatus(): DeviceStatusAppearance {
		let status = this.tempEidos?.status ?? this._status
		return this.statusAppearanceMap[status]
	}

	/**
	 * Gets the eidos value, if a temp value exists, provides that instead
	 */
	get eidos() {
		if (this.tempEidos) return this.tempEidos
		return this._eidos
	}

	// #endregion ================= Getters ================
	// #region ==================== Utility ================

	/**
	 * Checks if this device's fw version is at least the specified version
	 * @param fwVersion
	 */
	compareVersion(fwVersion: string) {
		return 0 // should be overloaded
	}

	/**
	 * Check if the device is ready to play the specified show
	 * @param show
	 * @returns
	 */
	isReadyToPlay(show: Lightshow | Scene) {
		return true
	}

	/**
	 * Check if the device is currently downloading the specified show
	 * @param show
	 * @returns
	 */
	isShowDownloading(show: Lightshow | Scene) {
		return false
	}

	/**
	 * Check if there is a newer version of the specified show available for this device
	 */
	isShowOutdated(show: Lightshow | Scene) {
		return false
	}

	// #endregion ============== Utility =================

	// #region ================= Device Variables =================

	public get supportsPassword() {
		const supportTypes = ["dev_luxedo", "dev_luxcast"]
		return supportTypes.includes(this.typeId)
	}

	public get hasConnectedProjector() {
		return false
	}

	get lumenCount(): number | null {
		return null
	}

	// #endregion =============== Device Variables ================

	public static getUIColorByIndex(index: number) {
		return `#${PROJECTOR_COLOR_OPTIONS[index % PROJECTOR_COLOR_OPTIONS.length]}`
	}

	public static getDeviceTypeName(type: string) {
		return TYPE_NAMES[type]
	}
}
