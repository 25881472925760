<script lang="ts">
	import {
		DataHandlerDevice,
		DataHandlerScene,
		type Device,
		Tag,
		type Scene,
		DataHandlerTag,
	} from "luxedo-data"
	import { DragController } from "../../../../stores/DragContext"

	import ProjectorIcon from "../../icons/ProjectorIcon.svelte"
	import { Toast } from "svelte-comps/toaster"
	import { TileLabel } from "svelte-comps/luxedo"
	import { PlusIcon, CheckboxIcon, UnderscaledIcon } from "svelte-comps/icons"
	import { openCtxMenu } from "svelte-comps/context-menu"
	import WarningIcon from "../../icons/WarningIcon.svelte"
	import { CTXOptions } from "../../../../modules/ctx-menu/ContextMenuOptions"
	import { ShowLibraryController } from "../../../routes/show-library/ShowLibraryController"
	import { SuperscaleProgressManager } from "../../../../modules/progress-reporting/ProgressContext_Superscale"
	import UnderscaleIndicator from "./scene/UnderscaleIndicator.svelte"

	let device: Device
	let tags: Array<Tag> = DataHandlerTag.getMany()

	DataHandlerTag.addListener(() => {
		tags = DataHandlerTag.getMany()
	})

	export let design: Scene
	export let isSelected: boolean = false
	export let onClick: () => void = undefined

	export let lightshowIndex: number = undefined
	export let remove: () => void = undefined

	export let showSelectOutline = false

	let triggerRename: () => void
	let isSelectedInLightshow = false

	let isUnderscaled = false
	let isSuperscaling = false

	ShowLibraryController.LightshowEditor.subscribe((ctx) => {
		isSelectedInLightshow = ctx.selectedScenes.includes(design)
	})

	function getCxtMenuOpts() {
		return CTXOptions.Scene.createOptions(design, { lightshowIndex })
	}

	let isDragging = false

	function onDragStart() {
		isDragging = true
	}

	function onDragEnd() {
		isDragging = false
	}

	async function onRename(newName: string) {
		try {
			await DataHandlerScene.rename(design, newName)
			Toast.success("Scene renamed.")
		} catch (e) {
			console.error("[ERROR] ", e)

			Toast.error("There was an error renaming this scene, please try again.")
		}
	}

	let unsubscriber
	async function initializeSceneDetails() {
		device = DataHandlerDevice.get(design.target_device_id)
		isUnderscaled = design.isUnderscaled

		if (isUnderscaled) {
			unsubscriber = await SuperscaleProgressManager.subscribeTo(design, (ctx) => {
				isSuperscaling = ctx.hasStarted
				if (ctx.hasFinished) {
					isSuperscaling = false
					isUnderscaled = false
					if (unsubscriber) unsubscriber()
				}
			})
		}
	}

	function onLightshowAddClick(repeat?: boolean) {
		if (!design.render_ver)
			return Toast.error(
				'This scene has not been rendered. To render, open this design within the editor and click the "Render" button.'
			)

		if (repeat || !isSelectedInLightshow) ShowLibraryController.LightshowEditor.addBlock(design)
		else ShowLibraryController.LightshowEditor.deleteScene(design)
	}

	$: design && initializeSceneDetails()
</script>

<div class={remove ? "lightshow-block" : ""}>
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
	<div
		class="tile-container {isDragging ? 'is-dragging' : ''} {isSelected
			? 'is-selected'
			: ''} {showSelectOutline ? 'show-lightshow-outline' : ''} {showSelectOutline &&
		isSelectedInLightshow
			? 'is-selected'
			: ''} {showSelectOutline && !design.render_ver ? 'unrendered' : ''} {isUnderscaled
			? 'underscale'
			: ''} {isSuperscaling ? 'supercale' : ''}"
		role="application"
		on:click={showSelectOutline ? () => onLightshowAddClick() : onClick}
		on:dragstart={DragController.onDragStart(design, onDragStart, lightshowIndex)}
		on:dragend={DragController.onDragEnd(onDragEnd)}
		on:touchstart={DragController.onDragStart(design, onDragStart, lightshowIndex)}
		on:contextmenu={(e) => openCtxMenu(e, getCxtMenuOpts())}
		title={design.name}
		draggable={(showSelectOutline || lightshowIndex !== undefined) && !!design.render_ver}
	>
		{#if isSelectedInLightshow && showSelectOutline}
			<button
				on:click|stopPropagation={() => onLightshowAddClick(true)}
				class="lightshow-repeat-scene-button small">Repeat Scene</button
			>
		{/if}
		<div class="tile-icon-container">
			<div class="tile">
				{#if showSelectOutline}
					<CheckboxIcon isSelected={isSelectedInLightshow} />
				{/if}
				<img src={design.src.thumbnail} alt="Scene Thumbnail" loading="lazy" />
			</div>
			<div class="projector-icon {!device ? 'warn' : ''}">
				{#if device}
					<ProjectorIcon {device} />
				{:else}
					<WarningIcon strokeColor="var(--color-error)" />
				{/if}
			</div>
			{#if isUnderscaled}
				<UnderscaleIndicator scene={design} />
			{/if}
		</div>
		<div class="tile-label-container">
			<TileLabel
				file={design}
				onRename={remove !== undefined ? undefined : onRename}
				bind:triggerRename
			/>
			<!-- {#if design.duration}
				<span class="tile-sublabel"> {design.duration} </span>
			{/if} -->
		</div>
	</div>
	{#if remove}
		<button title="Delete" class="icon" on:click={remove}>
			<PlusIcon />
		</button>
	{/if}
</div>

<style>
	.lightshow-block {
		position: relative;
	}

	.projector-icon {
		width: 1.5rem;
	}

	.projector-icon.warn {
		height: 1.5rem;
	}

	.show-lightshow-outline .tile {
		--tile-border-width: 4px;
		border: var(--tile-border-width) solid var(--light);
		position: relative;
		transition: border-color 250ms;
	}

	.show-lightshow-outline.is-selected .tile {
		border-color: var(--color-main);
	}

	.show-lightshow-outline.unrendered {
		opacity: 0.25;
	}

	.show-lightshow-outline.is-selected .lightshow-repeat-scene-button {
		opacity: 0;
	}

	.show-lightshow-outline.is-selected:hover .lightshow-repeat-scene-button {
		opacity: 1;
	}

	.show-lightshow-outline .tile :global(.checkbox-container) {
		position: absolute;
		height: 2rem;
		width: 2rem;
		top: calc(var(--tile-border-width) * -1);
		right: calc(var(--tile-border-width) * -1);
	}

	button.icon {
		padding: 0;
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		z-index: 6;
	}

	button.icon :global(svg) {
		margin: 0;
		width: 1rem;
		height: 1rem;
		transform: rotate(45deg);
	}

	button.icon :global(.svg-stroke) {
		stroke: var(--color-text);
	}

	button.icon:hover :global(.svg-stroke),
	button.icon:focus-visible :global(.svg-stroke) {
		stroke: var(--color-red);
	}

	.lightshow-repeat-scene-button {
		z-index: 100;
		position: absolute;
		top: calc(var(--tile-width) - 2rem);
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		transition:
			opacity 250ms,
			background-color 250ms,
			color 250ms;
	}

	.underscale .tile {
		border-color: var(--ct-red);
	}

	@keyframes superscale {
		0% {
			border-color: var(--color-text);
		}

		25% {
			border-color: var(--color-text);
		}

		50% {
			border-color: var(--color-text-light);
		}

		75% {
			border-color: var(--color-text);
		}

		100% {
			border-color: var(--color-text);
		}
	}

	.supercale .tile {
		animation: superscale 5s infinite;
	}
</style>
