import { DataHandlerScene, Scene } from "luxedo-data"
import { LuxedoRPC } from "luxedo-rpc"
import type { SuperscaleStatus } from "luxedo-rpc/src/types/SuperscaleTypes"
import { writable, type Writable } from "svelte/store"

// ! handle scenes with not isUnderscaled flag

/**
 * SuperscalingStatus_Scene :
 * index: scene id
 * value: {
 *		index: slot id
 * 		value: slot status (see above)
 * }
 */
type SuperscalingStatus_Scene =
	| {
			hasStarted: boolean
			hasFinished: boolean
			status: {
				[index: string]: SuperscaleStatus
			}
	  }
	| undefined

const POLL_INTERVAL = 5 * 1000 // 5 seconds in ms

class ProgressContext_Superscale {
	private stores: {
		[index: number]: Writable<SuperscalingStatus_Scene>
	}

	private interval: number // the interval id for the status polling

	constructor() {
		this.stores = {}
	}

	/**
	 * Creates stores for all scenes marked as "underscaled", getting the initial status of any superscale process
	 */
	async initialize(scene?: Scene) {
		const createStore = async (scene: Scene) => {
			const sceneStaus = await this.requestStatus(scene)
			return writable(sceneStaus)
		}

		if (scene) {
			this.stores[scene.id] = await createStore(scene)
			return
		}

		const underscaled = DataHandlerScene.getMany().filter((s) => s.isUnderscaled)
		for (const scene of underscaled) {
			this.stores[scene.id] = await createStore(scene)
		}
	}

	async subscribeTo(scene: Scene, cb: (ctx: SuperscalingStatus_Scene) => void) {
		if (!(scene.id in this.stores)) await this.initialize(scene)
		return this.stores[scene.id]?.subscribe(cb)
	}

	/**
	 * Pulls the status of the specified scene's superscale process from the server
	 * @param scene the scene to get the superscale status for
	 * @returns the new status
	 */
	private async requestStatus(scene: Scene): Promise<SuperscalingStatus_Scene> {
		const status = await LuxedoRPC.api.superscale.superscale_get_status(scene.id)
		if (status) {
			const processing = Object.values(status).filter((s) => s !== "NONE")
			let hasFinished =
				processing.length > 0 &&
				processing.filter((v) => v === "SUCCESS").length === processing.length

			return {
				status,
				hasStarted: processing.length > 0,
				hasFinished,
			}
		} // in case the process has started
		else return undefined // if the process never started
	}

	/**
	 * Updates the specified scene's store with the provided status
	 * @param scene The scene whos status is being updated
	 * @param status The status to update to
	 */
	private updateStore(scene: Scene, status: SuperscalingStatus_Scene) {
		this.stores[scene.id].set(status)
	}

	/**
	 * Stops any other active polling interval and begins polling to regularly update the status of the specified scene's superscale process.
	 * @param scene The scene being superscaled
	 */
	beginListening(scene: Scene) {
		this.stopListening()

		this.interval = setInterval(async () => {
			const status = await this.requestStatus(scene)
			this.updateStore(scene, status)
		}, POLL_INTERVAL)
	}

	/**
	 * Stops the active polling interval (if applicable).
	 * This method assumes there can only be one interval active at a time.
	 */
	stopListening() {
		if (!this.interval) return
		clearInterval(this.interval)
	}
}

export const SuperscaleProgressManager = new ProgressContext_Superscale()
