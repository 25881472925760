<script lang="ts">
	import { DataHandlerDevice, DataHandlerSnapshot, DeviceGroup } from "luxedo-data"
	import { getCurrentUser } from "../../../../../stores/UserStore"

	type ResolutionList = Array<{ x: number; y: number }>

	export let selectedSnapshotId: number
	export let selectedDeviceId: number

	let suggestedResolutions: ResolutionList = []

	function updateResolution() {
		suggestedResolutions = []
		const device = DataHandlerDevice.get(selectedDeviceId)

		if (device instanceof DeviceGroup) {
			suggestedResolutions.push({
				x: device.resX,
				y: device.resY,
			})

			if (getCurrentUser().hasPriv("commercial")) {
				suggestedResolutions.push({
					x: Math.ceil(device.resX / 2),
					y: Math.ceil(device.resY / 2),
				})
				suggestedResolutions.push({
					x: Math.ceil(device.resX / 4),
					y: Math.ceil(device.resY / 4),
				})
			}
		} else {
			const snapshot = DataHandlerSnapshot.get(selectedSnapshotId)

			suggestedResolutions.push({
				x: snapshot.resolution.w,
				y: snapshot.resolution.h,
			})
		}
	}

	function renderResolutions(resList: ResolutionList) {
		let str = ""
		for (let i = 0; i < resList.length; i++) {
			const res = resList[i]

			let seperator = ", "
			if (i === resList.length - 1) seperator = " or "
			else if (i === 0) seperator = ""

			str = `${str}${seperator}${res.x}x${res.y}`
		}
		return str
	}

	$: (selectedSnapshotId || selectedDeviceId) && updateResolution()
</script>

<div class="flex-column">
	<h3>Export your project</h3>
	<p>Export your project, making sure that:</p>
	<ul>
		<li>Your snapshot image has been hidden before rendering your scene.</li>
		<li>The scene is being exported to the <span class="highlight">MP4</span> format.</li>
		{#if suggestedResolutions.length === 1}
			<li>
				Your scene is being exported to the correct resolution <span class="highlight"
					>({suggestedResolutions[0].x ?? "0"}x{suggestedResolutions[0].y ?? "0"})</span
				>.
			</li>
		{:else}
			<li>
				Your scene is being exported to a valid resolution matching the aspect ratio <span
					class="highlight">({renderResolutions(suggestedResolutions)})</span
				>.
				<span style="font-weight: bold;"
					>The aspect ratio must match the original resolution and be no less than 1/4 of the
					resolution.</span
				>
			</li>
		{/if}
	</ul>
	<p>Once exported, move on to the next step to upload.</p>
	<br />
	<p>If you are working with a professional, you can skip this step.</p>
</div>

<style>
	p {
		margin: 1rem 0 0 0;
		color: var(--color-text);
	}

	.highlight {
		color: var(--color-main);
	}

	ul {
		list-style: disc;
		margin-left: 1rem;
		color: var(--color-text);
	}
</style>
