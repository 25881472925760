<script lang="ts">
	import { RollbackFeedbackError } from "../../../../../../types/ErrorVariants"

	export let postSubmit: () => void

	let reason: string

	function submit() {
		postSubmit()
		throw new RollbackFeedbackError(reason)
	}
</script>

<form id="rollback-report">
	<p>
		To help us improve future releases, please let us know what issues you encountered or the reason
		for the rollback. Your feedback is invaluable in enhancing the quality of our updates.
	</p>
	<textarea name="" id="" bind:value={reason} placeholder="Enter your reason..."></textarea>
	<button id="rollback-button" on:click={submit}>Rollback</button>
</form>

<style>
	#rollback-report {
		margin-bottom: 2rem;
	}

	p {
		width: 48rem;
		max-width: 50vw;
		margin-top: 1rem;
	}

	textarea {
		background-color: var(--color-bg-dark);
	}

	textarea::placeholder {
		color: var(--color-text);
	}

	#rollback-button {
		position: absolute;
		bottom: 2rem;
		right: 3rem;
	}
</style>
