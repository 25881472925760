<script lang="ts">
	import { DataHandlerDevice, type Device } from "luxedo-data"
	import CheckboxInput from "../../../input/CheckboxInput.svelte"
	import { ShowOverviewController } from "../ShowOverviewController"
	import { UserStore } from "../../../../../stores/UserStore"

	let selectedDeviceId: number
	let doUpdateAllScenes: boolean = false

	let availableDevices: Array<Device>
	UserStore.subscribe(() => {
		availableDevices = DataHandlerDevice.getMany()
	})

	$: selectedDeviceId > -1 && ShowOverviewController.setDevice(selectedDeviceId)
	$: ShowOverviewController.setDoUpdateAllScenes(doUpdateAllScenes)
</script>

<div id="assign-new-device">
	<div class="select-container">
		<label for="scene-projector-input">Select Projector:</label>
		<select id="scene-projector-input" bind:value={selectedDeviceId}>
			<option value={-1}>Unassigned</option>
			{#each availableDevices as device}
				<option value={device.id}>{device.name}</option>
			{/each}
		</select>
	</div>

	<CheckboxInput label="Transfer all scenes and lightshows?" bind:isChecked={doUpdateAllScenes} />
</div>

<style>
	.select-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.select-container select {
		text-align: start;
		padding: 0.1em 0;
	}

	.select-container label {
		padding-left: 0.25rem;
	}

	#assign-new-device :global(.input-container) {
		margin-top: 0.5rem;
	}

	#assign-new-device :global(.input-container label) {
		font-size: var(--h3);
	}
</style>
