<script lang="ts">
	import { DataHandlerCalibration, DeviceGroup, type Device } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../../stores/SelectedDeviceStore"
	import CalibrationList from "./calibration-list/CalibrationList.svelte"
	import Guides from "luxedo-data/src/data/Guides"
	import { Toast } from "svelte-comps/toaster"
	import { tooltip } from "svelte-comps/tooltip"
	import { openCalibrationOverlay } from "../../../../reusable/overlays/calibration"
	import { onMount } from "svelte"
	import { DeviceSpotlightManager } from "luxedo-data"
	import { CalibrationOverlayController } from "../../../../reusable/overlays/calibration/CalibrationOverlayController"
	import { hideMinimizedCalibration } from "../../../../reusable/overlays/calibration/calibration-minimized"

	// Render this if the user has no calibrations
	const noCalText = `This device has not yet been calibrated, click "Calibrate Projector" below to get started.`
	// Render this if the user has only failed calibrations
	const failedCalText = `This device has not yet been successfully calibrated. If you running into issues when calibrating, check out <a target="_blank" href="${Guides["Device"]["Troubleshooting Calibration"]}">this article</a>.`

	export const triggerSave = null

	let isCalibrated: boolean = false

	let device: Device

	let isSpotlightActive = false
	let isSpotlightToggling = false

	SelectedDeviceStore.subscribe((dev) => {
		if (dev instanceof DeviceGroup) return

		device = dev
		isSpotlightToggling = false
		isSpotlightActive = DeviceSpotlightManager.getSpotlightStatus(device)

		isCalibrated = device?.isCalibrated ?? false
	})

	DeviceSpotlightManager.subscribe((ctx) => {
		if (!device) isSpotlightActive = false
		else isSpotlightActive = ctx[device.id] ?? false
	})

	function beginCalibration() {
		if (!device.isOnline) return Toast.error("Device must be online to calibrate!")
		// If device is already calibrating
		if (
			CalibrationOverlayController.get().step === 1 &&
			device.id === CalibrationOverlayController.device?.id
		) {
			hideMinimizedCalibration()
			openCalibrationOverlay(device, true)
		} else openCalibrationOverlay(device)
	}

	async function toggleSpotlight() {
		isSpotlightToggling = true
		if (isSpotlightActive) await DeviceSpotlightManager.deactivateSpotlight(device)
		else await DeviceSpotlightManager.activateSpotlight(device)
		isSpotlightToggling = false
	}

	function listenForCalibration() {
		DataHandlerCalibration.addListener(() => {
			if (!device) return
			isCalibrated = device.isCalibrated
		})
	}

	onMount(listenForCalibration)
</script>

<div class="calibration-menu">
	{#if !isCalibrated}
		<p class="no-cals">
			⚠
			{#if DataHandlerCalibration.filterByDevice(device).length}
				{@html failedCalText}
			{:else}
				{@html noCalText}
			{/if}
		</p>
	{/if}
	<div class="button-container calibration-actions">
		<button
			id="calibrate-button"
			class="outline-button"
			on:click={beginCalibration}
			use:tooltip={{
				content:
					"Calibration is the process of shining and capturing patterns from your Luxedo in order to accurately map your projection space.",
				pointing: "top",
			}}>{isCalibrated ? "Rec" : "C"}alibrate Projector</button
		>
		<button
			class="outline-button alt"
			id="activate-spotlight-button"
			on:click={toggleSpotlight}
			disabled={isSpotlightToggling}
			use:tooltip={{
				content:
					"Enabling the projector spotlight will trigger your device to project white light allowing you to better visualize and adjust your device's position.",
				pointing: "top",
			}}
		>
			{#if isSpotlightToggling}
				{isSpotlightActive ? "Deactivating..." : "Activating..."}
			{:else}
				{isSpotlightActive ? "Deactivate" : "Activate"} Spotlight
			{/if}
		</button>
	</div>
	{#if isCalibrated}
		<CalibrationList {device} />
	{/if}
</div>

<style>
	.calibration-menu {
		width: 100%;
		overflow-y: hidden;
		display: flex;
		flex-direction: column;
	}

	#calibrate-button {
		flex-grow: 1;
	}

	#activate-spotlight-button {
		min-width: fit-content;
		flex-basis: 30%;
		margin-left: 1rem;
	}

	.calibration-actions :global(.info-dialogue-button) {
		margin-left: -0.1rem;
	}

	p.no-cals {
		color: var(--color-main);
		font-size: var(--h2);
		margin: 0 0 1rem 0;
	}

	.no-cals :global(a) {
		color: var(--color-text-light);
		border-color: var(--color-text-light);
	}
</style>
