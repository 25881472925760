<script lang="ts">
	import { DataHandlerDevice, DeviceGroup, DeviceRPi, Scene } from "luxedo-data"
	import { ShowOverviewController } from "../ShowOverviewController"
	import { SuperscaleProgressManager } from "../../../../../modules/progress-reporting/ProgressContext_Superscale"
	import type { SuperscaleStatus } from "luxedo-rpc/src/types/SuperscaleTypes"
	import { onDestroy, onMount } from "svelte"
	import type { Unsubscriber } from "svelte/store"
	import { LuxedoRPC } from "luxedo-rpc"
	import { Toast } from "svelte-comps/toaster"

	let scene: Scene

	let group: DeviceGroup
	let groupSlots: { [index: string]: DeviceRPi } = {}

	let prog: { [index: string]: SuperscaleStatus }
	let hasStarted: boolean
	let hasFinished: boolean
	let hasFailed: boolean

	let buttonDisabled = false

	ShowOverviewController.subscribe(({ show, device }) => {
		if (show instanceof Scene) scene = show
		else return (scene = undefined)

		if (device instanceof DeviceGroup) group = device
		else return (device = undefined)
	})

	let unsubscriber: Unsubscriber
	async function initialize(scene: Scene) {
		if (unsubscriber) unsubscriber()

		groupSlots = {}
		if (!group) return
		group.slots.forEach((slot) => {
			groupSlots[slot.id] = DataHandlerDevice.get(slot.device_id) as DeviceRPi
		})

		unsubscriber = await SuperscaleProgressManager.subscribeTo(scene, (ctx) => {
			prog = ctx.status
			hasFinished = ctx.hasFinished
			hasStarted = ctx.hasStarted

			buttonDisabled = false // wait to get scale progress before disabling the button
			hasFailed = Object.values(ctx.status).includes("FAILURE")

			if (hasFinished) SuperscaleProgressManager.stopListening()
		})
	}

	function getSlotName(slotId: string) {
		return groupSlots[slotId]?.name ?? "Loading..."
	}

	function getSlotColor(slotId: string) {
		return groupSlots[slotId]?.color ?? "var(--color-text-light)"
	}

	const startSuperscale = async () => {
		buttonDisabled = true
		SuperscaleProgressManager.beginListening(scene)
		try {
			await LuxedoRPC.api.superscale.superscale_start(scene.id)
			Toast.success("Scaling scene...")
		} catch (e) {
			console.warn("ERROR WHILE STARTING SCENE", e)
			let errorString =
				"An unknown error occurred while trying to start the scale up process. Please refresh and try again."
			switch (e.statusCode) {
				case 404:
					errorString = "Unable to find the scene to start scaling. Please refresh and try again."
					break
				case 403:
					errorString = "You do not have the permission to scale this show."
					break
				case 501:
					errorString = "This show is not associated with a device group."
					break
			}
			Toast.error(errorString)
		}
		// does not get auto disabled until progress updates
		// buttonDisabled = false
	}

	const cancelSuperscale = async () => {
		buttonDisabled = true

		try {
			await LuxedoRPC.api.superscale.superscale_cancel(scene.id)
			Toast.success("Successfully cancelled scale operation.")
		} catch (e) {
			let errorString =
				"An unknown error occurred while trying to cancel the scale up process. Please refresh and try again."
			switch (e.statusCode) {
				case 404:
					errorString = "Unable to find the scene to cancel scaling. Please refresh and try again."
					break
				case 403:
					errorString = "You do not have the permission to cancel this operation."
					break
				case 501:
					errorString = "This show is not associated with a device group."
					break
			}
			Toast.error(errorString)
		}
		buttonDisabled = false
	}

	function formatProgress(progress: string) {
		switch (progress) {
			case "NONE":
				return "Stopped"
			case "FAILURE":
				return "Failed"
			case "STARTED":
				return "Processing"
			case "PENDING":
				return "Queued"
			default:
				return `${progress.charAt(0).toUpperCase()}${progress.toLowerCase().slice(1)}`
		}
	}

	onMount(() => {
		SuperscaleProgressManager.beginListening(scene)
	})

	onDestroy(() => {
		SuperscaleProgressManager.stopListening()
	})

	$: initialize(scene)
</script>

<div class="superscale-progress">
	<span class="flex-row">
		<h3>
			Scaling Status
			{#if hasFinished}
				<span style="color: var(--ct-green)">✓</span>
			{/if}
		</h3>
		{#if !hasStarted}
			<button
				id="scale-up-button"
				class="small"
				title="Start Superscale"
				on:click={startSuperscale}
				disabled={buttonDisabled}>Scale Up</button
			>
		{:else if hasFailed}
			<button
				id="scale-up-button"
				class="small"
				title="Start Superscale"
				on:click={startSuperscale}
				disabled={buttonDisabled}>Retry Failed Sections</button
			>
		{:else if !hasFinished}
			<button
				id="scale-up-button"
				class="small warn"
				title="Cancel Superscale"
				disabled={buttonDisabled}
				on:click={cancelSuperscale}>Cancel</button
			>
		{/if}
	</span>
	{#if hasFinished}
		<div class="underscaled-info">
			<p>This scene has been succesfully scaled up.</p>
		</div>
	{:else if hasStarted}
		<table>
			<thead>
				<th>Device Slot</th>
				<th>Status</th>
			</thead>
			<tbody>
				{#each Object.entries(prog) as [slotId, progress]}
					<tr>
						<td style="color: {getSlotColor(slotId)};">{getSlotName(slotId)}</td>
						<td class="progress-string">{formatProgress(progress)}</td>
					</tr>
				{/each}
			</tbody>
		</table>
	{:else if !hasFinished}
		<div class="underscaled-info">
			<p>
				This scene is underscaled.

				<br />To begin scaling this scene up, click "Scale Up".
			</p>
		</div>
	{/if}
</div>

<style>
	.superscale-progress {
		margin-top: 1rem;
		grid-row: 2;
	}

	table {
		margin-left: 1rem;
	}

	thead {
		color: var(--color-text);
	}

	td {
		padding-right: 1rem;
	}

	.progress-string {
		padding: 0;
	}

	.underscaled-info {
		margin-left: 1rem;
		color: var(--color-text);
	}

	#scale-up-button {
		margin-left: 1rem;
	}
</style>
