import {
	DataHandlerScene,
	DataHandlerLightshow,
	DataHandlerDevice,
	DataHandlerFolder,
	DataHandlerMedia,
	DataHandlerUser,
	DataHandlerSnapshot,
	DataHandlerTag,
	ChargebeeMngr,
	DataHandlerMask,
	DeviceSpotlightManager,
} from "luxedo-data"

import { PageInit } from "svelte-comps/auth"
import { UserStore, getCurrentUser } from "../../stores/UserStore"
import { FileSystemMedia } from "../file-system/FileSystemMedia"
import { DeviceUpdateManager } from "../device-operation-managers/DeviceUpdateManager"
import { DataHandlerCalibration } from "luxedo-data/src/datahandlers/DataHandlerCalilbration"
import { ProjectorPowerManager } from "luxedo-data"
import { DevicePlaybackManager } from "luxedo-data"
import { SuperscaleProgressManager } from "../progress-reporting/ProgressContext_Superscale"
import { LuxedoRPC } from "luxedo-rpc"
import { POLL_INTERVAL_SLOW } from "luxedo-rpc/src/backend/consts"

const DATAHANDLERS = [
	DataHandlerMedia,
	DataHandlerFolder,
	DataHandlerTag,
	DataHandlerScene,
	DataHandlerSnapshot,
	DataHandlerDevice,
	DataHandlerLightshow,
	DataHandlerCalibration,
	DataHandlerMask,
]

export const pageInit = new PageInit(
	DATAHANDLERS,
	DataHandlerUser,
	import.meta.env.VITE_API_URL,
	UserStore.set
)

LuxedoRPC.backend.stopPolling()

export const ChargebeeManager = new ChargebeeMngr()

async function initiateDevices() {
	const devices = DataHandlerDevice.getMany()
	for (const device of devices) {
		await device.timetableManager?.refresh()
	}
}

pageInit.postInit(async () => {
	LuxedoRPC.backend.startPolling(POLL_INTERVAL_SLOW)
	DataHandlerMedia.bindAlterEndpoints()
	await ChargebeeManager.pullPlans(getCurrentUser())
	DataHandlerFolder.calculateAllFolderStorage()
	FileSystemMedia.initialize()
	await initiateDevices()
	DeviceUpdateManager.initialize()
	ProjectorPowerManager.initialize()
	DevicePlaybackManager.initialize()
	DeviceSpotlightManager.initialize()
	SuperscaleProgressManager.initialize()
})
